import React, { useState, useEffect } from "react";
import AgeConfirmation from "../components/AgeConfirmation";
import Imagesplit from "../components/Imagesplit";
import BannerSlider from "../components/BannerSlider"
import ImageSequenceCanvas from '../components/ImageSequenceCanvas';
import ImageSequenceMobile from '../components/ImageSequenceMobile';
import MediaQuery from 'react-responsive'

const HomepagePart = () => {
	useEffect(() => {
		var sections = $('section')
$(window).on('scroll', function() {
    var cur_pos = $(this).scrollTop();
    sections.each(function() {
        var top = $(this).offset().top -300,
            bottom = top + $(this).outerHeight();
        if (cur_pos >= top && cur_pos <= bottom) {
            //nav.find('a').removeClass('active');
            // sections.removeClass('active');
            $(this).addClass('activetitle');
        }
    });
});
}, []);

useEffect(() => {
  // Animation for .clound-image
  var clouds = $('.clound-image'),
    timeline = [];
  clouds.each(function (clIndex) {
    var measure = clouds.length - 1,
      delay = -(measure + 2 - clIndex),
      start = 1 * clIndex,
      end = +100 - 100 * (measure - clIndex);
    timeline[clIndex] = TweenMax.fromTo($(this), 90, { transform: 'translateX(+' + start + 'vw)' }, { transform: 'translateX(' + end + 'vw)', ease: Linear.easeNone, repeat: -1 }, delay);
  });

  // animation two
  var clouds = $('.clound-image-one'),
  timeline = [];
clouds.each(function (clIndex) {
  var measure = clouds.length - 1,
    delay = -(measure + 2 - clIndex),
    start = 1 * clIndex,
    end = +100 - 100 * (measure - clIndex);
  timeline[clIndex] = TweenMax.fromTo($(this), 90, { transform: 'translateX(+' + start + 'vw)' }, { transform: 'translateX(' + end + 'vw)', ease: Linear.easeNone, repeat: -1 }, delay);
});

  // Animation for .clound-image2
  var cloud2 = $('.clound-image2'),
    timeline2 = [];
  cloud2.each(function (cloudIndex) {
    var measure = cloud2.length - 1,
      delay = -(measure + 2 - cloudIndex),
      start = 1 * cloudIndex,
      end = -100 - 100 * (measure - cloudIndex);
    timeline2[cloudIndex] = TweenMax.fromTo($(this), 100, { transform: 'translateX(+' + start + 'vw)' }, { transform: 'translateX(' + end + 'vw)', ease: Linear.easeNone, repeat: -1 }, delay);
  });

   // Animation for .clound-image2
   var cloud2 = $('.clound-image2-two'),
   timeline2 = [];
 cloud2.each(function (cloudIndex) {
   var measure = cloud2.length - 1,
     delay = -(measure + 2 - cloudIndex),
     start = 1 * cloudIndex,
     end = -100 - 100 * (measure - cloudIndex);
   timeline2[cloudIndex] = TweenMax.fromTo($(this), 90, { transform: 'translateX(+' + start + 'vw)' }, { transform: 'translateX(' + end + 'vw)', ease: Linear.easeNone, repeat: -1 }, delay);
 });

}, []);

  return (
    <>

      <MediaQuery minWidth={768}>
        {(matches) => matches ? (
          <div className="nopage_main">
            <ImageSequenceCanvas
              scrollHeight={2000}
              numFrames={100}
              width={1920}
              height={940}
            />
          </div>
        ) : null}
      </MediaQuery>
      
      <MediaQuery maxWidth={767}>
        {(matches) => matches ? (
         <div className="nopage_main only_mobile">
            <ImageSequenceMobile
              scrollHeight={2000}
              numFrames={100}
              width={680}
              height={580}
            />
          </div>
        ) : null}
      </MediaQuery>
   
	
      <section className="main-inside mt-160vh page-section">
      <MediaQuery minWidth={768}>
        {(matches) => matches ? (
          <>
            <div className="mountain1 clound-image" id="">
              <img src="images/cloud1.png" alt="cloud1" />
            </div>
            <div className="mountain2 clound-image2" id="">
              <img src="images/cloud2.png" alt="cloud2" />
            </div>
          </>
            ) : null}
      </MediaQuery>
        
      <MediaQuery maxWidth={767}>
        {(matches) => matches ? (
          <>
          <div className="mountain1 clound-image" id="">
            <img src="images/cloud1mob.webp" alt="cloud1mob" />
          </div>
          <div className="mountain2 clound-image2" id="">
            <img src="images/cloud2mob.webp" alt="cloud2mob" />
          </div>
        </>
        ) : null}
      </MediaQuery>

        <div className="secion1">
          <div className="">
            <div className="animate-text">
            <h1 className="h2 text-center site-color animatetext"> 
              THE SPIRIT OF <br/> THE HIMALAYAS
            </h1>
              </div> 
            <div className="v-line">
              <svg
                className="sc-16mwcw0-0 iZNjkc"
                width="2"
                height="283"
                viewBox="0 0 2 283"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M1 0L0.999988 283" stroke="#7E573E" strokeWidth="2" />
              </svg>
            </div>
            <div className="text-center secion1_content">
              <p>
              Anchored in age-old Indian wisdom and practices,<br/>
              IDAAYA is a sipping rum crafted for the seeking soul.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="video-section">
        <button className="video-btn">
          <img src="images/video-play.svg" alt="video" />
        </button>
      </section>

      <section className="home-secion4 page-section">
      <MediaQuery minWidth={768}>
      {(matches) => matches ? (
          <>
           <div className="vertical_moutain_1">
              <img src="images/left.png" alt="side mountain" />
            </div>
            <div className="vertical_moutain_2">
              <img src="images/right.png" alt="side mountain right" />
            </div>
          </>
            ) : null}
      </MediaQuery>
        <div className="container small-container text-center">
          <h2 className="site-color text-uppercase animatetext mb-60">
          keep seeking   
            </h2>
          <div className="gray-text">
            <p>
            The Himalayas bring different things to different people.<br/>
A tryst with the mighty mountains yields a multitude of emotions —<br/>
but, above all, it offers us a glimpse into the infinite.
            </p>
            <p>
            Amidst the chaos of everyday life, IDAAYA aims to offer you <br/>
             the same limitless discovery — through a moment.
            </p>
           
            <div className="v-line small-line">
              <svg
                className="sc-16mwcw0-0 iZNjkc"
                width="2"
                height="283"
                viewBox="0 0 2 283"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M1 0L0.999988 283" stroke="#7E573E" strokeWidth="2" />
              </svg>
            </div>
            <p className="italicText">
              A moment of stillness, a pause,<br/> a moment of reflection and contemplation,<br /> a moment of reverence, <br />
               a moment of solace.
            </p>
          </div>
        </div>
      </section>

      <Imagesplit />


      
      <section className="home-secion5 pt-50 homeprimary_Sec">
      <div className="mountain1 clound-image-one cloud-left" id="">
    <img src="images/cloud1.png" alt="cloud1" />
</div>
<div className="mountain2 clound-image2-two cloud-right" id="">
    <img src="images/cloud2.png" alt="cloud2" />
</div>
<div className="v-line">
              <svg
                className="sc-16mwcw0-0 iZNjkc"
                width="2"
                height="283"
                viewBox="0 0 2 283"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M1 0L0.999988 283" stroke="#7E573E" strokeWidth="2" />
              </svg>
            </div>
        <div className="container text-center">
          <h2 className="site-color text-uppercase animatetext mb-60 text-center">
          Our premium dark rum is<br/> best enjoyed on its own, <br/>sipped on the rocks, or garnished <br/>with a burnt orange twist.
            </h2>
          <div className="gray-text">
            {/* <p>
            IDAAYA PAIRINGS
            </p> */}
          
            <div className="v-line small-line">
              <svg
                className="sc-16mwcw0-0 iZNjkc"
                width="2"
                height="283"
                viewBox="0 0 2 283"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M1 0L0.999988 283" stroke="#7E573E" strokeWidth="2" />
              </svg>
            </div>
           
          </div>
        </div>
      </section>

    </>
  );
};

export default HomepagePart;
